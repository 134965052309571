import { useState } from 'react';
import FsLightbox from 'fslightbox-react';

import { lightboxButtons } from '../utils/helpers';

function Gallery({ title, projectList, description }) {
    const [lightboxToggle, setLightboxToggle] = useState({ toggler: false, sourceIndex: 0 });
    function openLightBoxSource(sourceIndex) {
        setLightboxToggle({ toggler: !lightboxToggle.toggler, sourceIndex: sourceIndex });
        setTimeout(lightboxButtons, 1);
    }
    let images = [];
    let imgTitles = [];
    projectList.forEach(img => { images.push(require(`../assets/images/${img.image}`)) });
    projectList.forEach(img => { imgTitles.push({alt: img.title}) });

    return(
        <>
        <section>
            {title && <h3>{title}</h3>}
            <div>
                {description && description.map(desc => (
                    <p key={desc}>
                        {desc}
                    </p>
                ))}
                <p><i>Click thumbnails to see larger image.</i></p>
                <div className='gallery'>
                    {projectList.map((img, i) => (
                        <img src={require(`../assets/images/${img.image}`)} alt={img.title} key={img.slug} onClick={() => openLightBoxSource(i)} />
                    ))}
                </div>
            </div>
        </section>
        <FsLightbox
            toggler={lightboxToggle.toggler}
            sourceIndex={lightboxToggle.sourceIndex}
            sources={images}
            customAttributes={imgTitles}
        />
        </>
    );
}

export default Gallery;