export const webProjectInfo = [
    {
        title: 'Maintainable',
        technologies: ['HTML', 'CSS', 'JavaScript', 'Node.js', 'MySQL', 'Express', 'Handlebars', 'Sequelize'],
        thumbnail: 'Web/Maintainable/wide-customer-search.png',
        repo: null,
        client: 'Maintainable & Simmark.com.au',
        description: [
            'With a need for a customer, employee, and asset database that allows for different information to be stored, Simmark, an Australian-based company, contacted me to help them make this for their company, with intent of future distribution as a service for other companies. I joined a small team of developers as primarily a front-end developer to bring their need and vision to fruition. The application allows companies to maintain and manage their customers and the customers\' assets (including contracts, warranties, reports, services, and images) so they could better service their clients.',
            'I worked on the application\'s structure, as well as creating the design and styling. I primarily worked with CSS and JavaScript on the style and functionality of buttons, navigation, search, forms, and mobile responsiveness; and used Handlebars to create the HTML templating for the site. Though I wasn\'t the primary back-end coder, I did some substantial route coding for pages and routes for editing/deleting items, as well as provided input on how to make the models more flexible for expansion of the application in the future. The color theme of the application depends on the company\'s main color, to make the application more personable for the company.',
            'Note: All names, addresses, and other information was randomly generated for this preview and does not belong to real customers.'
        ],
        gallery: ['Web/Maintainable/wide-customer-search.png', 'Web/Maintainable/wide-customer-profile.png', 'Web/Maintainable/wide-asset-profile.png', 'Web/Maintainable/wide-asset-images.png', 'Web/Maintainable/wide-add-asset.png', 'Web/Maintainable/wide-admin-dash.png', 'Web/Maintainable/mobile-navigation.png', 'Web/Maintainable/mobile-customer-search.png', 'Web/Maintainable/mobile-customer-profile-assets-tab.png', 'Web/Maintainable/mobile-asset-profile.png', 'Web/Maintainable/mobile-admin-dash.png'],
        slug: 'maintainable'
    },
    {
        title: 'West Valley Digital Media Site',
        technologies: ['HTML', 'CSS', 'PHP'],
        thumbnail: 'Web/WVDM/WVDM_Web01.jpg',
        repo: null,
        url: 'http://digital-media.westvalley.edu/',
        client: 'Digital Media Department at West Valley Collage',
        description: [
            'The West Valley Ditial Media website needed an update in its coding, so I coded a new layout from scratch and incorporated existing and updated information. It was required I use the school\'s colors of orange, navy blue, and white, and use the Digital Media logo provided to me by the department. I used simple sans-serif fonts to make reading easy on the eyes and incorporated a lightbox for the image samples.'
        ],
        gallery: ['Web/WVDM/WVDM_Full.jpg', 'Web/WVDM/WVDM_Web01.jpg', 'Web/WVDM/WVDM_Web02.jpg', 'Web/WVDM/WVDM_Web03.jpg', 'Web/WVDM/WVDM_Web04.jpg', 'Web/WVDM/WVDM_Responsive01.jpg', 'Web/WVDM/WVDM_Responsive02.jpg', 'Web/WVDM/WVDM_Responsive03.jpg'],
        slug: 'wvdm'
    },
    {
        title: 'RANDM',
        technologies: ['Node.js', 'MySQL', 'Express', 'Handlebars', 'Sequelize', 'Session', 'Socket.io', 'JavaScript', 'CSS'],
        thumbnail: 'Web/Randm/main.jpg',
        repo: 'https://github.com/JColeCodes/randm',
        url: 'https://randm.apps.colecodes.com/',
        client: null,
        description: [
            'RANDM is a dating application that helps users find matches through randomization. While other dating apps on the market focus too much on matches based on initial judgement, with RANDM, people can start chatting with and getting to know potential dates without that initial judgement in an app free of profile pictures and "swiping".',
            'This application uses a MySQL database and RESTful APIs to save users and connect them for messaging. This was a group project and for it, I managed the project, did the frontend style, linked the Express backend to the Handlebars frontend, and implemented Socket.io for live messages. In addition to the coding, I also developed the logo and branding of this project. I used Adobe Illustrator to create the logo as a vector.'
        ],
        gallery: ['Web/Randm/main.jpg', 'Web/Randm/chat-wide.jpg', 'Web/Randm/mobile-chat.jpg', 'Web/Randm/mobile-toggle.jpg'],
        slug: 'randm'
    },
    {
        title: 'Travel Through Time (Itinerary)',
        technologies: ['HTML', 'JavaScript', 'jQuery', 'DOM', 'API', 'Foundation', 'Moment.js'],
        thumbnail: 'Web/TravelItinerary/screenshot-index.jpg',
        repo: 'https://github.com/JColeCodes/travel-itinerary',
        url: 'https://jcolecodes.github.io/travel-itinerary/',
        client: null,
        description: [
            'Travel Through Time offers a way to explore the historical sites, monuments, museums, and more with a click of a button. On the index page, the user can input information about where they plan to travel to. On the results page, several APIs are used in order to use the search criteria to find places within the city/state/country the user is searching for. Users are able to save results to their itinerary. The itinerary page includes the saved activities from the results page, as well as a currency exchange, budget tracker, and the itinerary of the trip.',
            'This application uses a series of RESTful APIs to get information, then saves the user\'s itinerary information in localStorage. I managed the project, did all of the search functions, and used jQuery UI to enable dragging and dropping'
        ],
        gallery: ['Web/TravelItinerary/screenshot-index.jpg', 'Web/TravelItinerary/screenshot-results.jpg', 'Web/TravelItinerary/screenshot-itinerary.jpg'],
        slug: 'travel-itinerary'
    },
    {
        title: 'Tech Blog',
        technologies: ['Node.js', 'MySQL', 'Express', 'Handlebars', 'Sequelize', 'Session', 'JavaScript', 'CSS'],
        thumbnail: 'Web/TechBlog/main.png',
        repo: 'https://github.com/JColeCodes/tracking-tech',
        url: 'https://tech-blog.apps.colecodes.com/',
        client: null,
        description: [
            'This tech blog is a simple CMS-style blog that displays posts and comments. Users can log in (or register, if they do not already have an account) to post blog entries and make comments on the site. Logged in users have a dashboard that not just allows them to create a new post, but access a list of their previous posts with links to view, edit, or delete those posts. After an hour of inactivity, users are logged out. This application uses Node.js with Express server, saving information to a MySQL database, and is deployed on Heroku.'
        ],
        gallery: ['Web/TechBlog/main.png', 'Web/TechBlog/single.png', 'Web/TechBlog/dashboard.png', 'Web/TechBlog/login.png'],
        slug: 'tech-blog'
    },
    {
        title: 'Weather Dashboard',
        technologies: ['HTML', 'CSS', 'JavaScript', 'API'],
        thumbnail: 'Web/WeatherDash/screenshot.jpg',
        repo: 'https://github.com/JColeCodes/climate-checker',
        url: 'https://jcolecodes.github.io/climate-checker/',
        client: null,
        description: [
            'Using the OpenWeather API, this weather dashboard allows users to look up cities across the world to view the current weather and weather of the upcoming 5 days. Each city search displays the current day, time, weather, wind speed, humidity, and UV Index, along with icons for both the current weather and the country flag. The five-day forecast shows the predicted average temperature, wind speed, and humiditiy, as well as an icon of the weather.',
            'The search is intutive to allow users to look up multiple cities of the same name (like Salem, MA and Salem, OR). The ten most recent searches show up under the search box for easy access to recently seen cities (the most recent being on top). (The amount of recent cities decreases from ten to five for mobile phones, so not too much valuable screenspace is taken up). However, if a search is vague, the user is also presented with a message and links to additional cities under a similar name.',
            'In the top right, there is a toggle button to change units from Fahrenheit (the default) to Celsius, and in doing so, also changes miles to km (for the speed) and the date format from month/day to day/month.'
        ],
        gallery: ['Web/WeatherDash/screenshot-wide.png', 'Web/WeatherDash/screenshot-ipad.png', 'Web/WeatherDash/screenshot-mobile1.png', 'Web/WeatherDash/screenshot-mobile2.png'],
        slug: 'weather-dashboard'
    },
    {
        title: 'JavaScript Coding Quiz',
        technologies: ['JavaScript', 'DOM'],
        thumbnail: 'Web/JSQuiz/page-question.jpg',
        repo: 'https://github.com/JColeCodes/program-exam',
        url: 'https://jcolecodes.github.io/program-exam/',
        client: null,
        description: [
            'This JavaScript Coding Quiz starts as soon as the user clicks the "Start Quiz" button. There are ten multiple choice questions. The answers are logged when the user clicks, so at the end of the game, they are able to see which questions they got right or wrong. The user can save their score to the high scores page with initials up to three characters in length. (High scores are saved only in localStorage.)',
            'During the quiz, the timer counts down starting at an avarage of 10 seconds per question. If an answer is wrong, 10 seconds are subtracted from the counter. However, if an answer is correct, the user gains 10 points. Any remaining seconds at the end of the game are counted as points toward the final score. If the timer runs out, the quiz is over, reglardless of how many questions were answered.'
        ],
        gallery: ['Web/JSQuiz/page-question.jpg', 'Web/JSQuiz/page-results.jpg', 'Web/JSQuiz/page-score.jpg'],
        slug: 'javascript-quiz'
    },
    {
        title: 'Budget Tracker (PWA)',
        technologies: ['Manifest', 'PWA', 'Service Worker', 'IndexedDB'],
        thumbnail: 'Web/BudgetTracker/installed-site.png',
        repo: 'https://github.com/JColeCodes/cash-cache',
        url: 'https://colecodes.com/apps/budget-tracker/',
        client: null,
        description: [
            'A simple budget tracker webpage has been upgraded to a progressive web app (PWA)! This application uses service workers to store the page files in a cache for offline access, as well as uses IndexedDB to save any input made while offline, so it can automatically submit when online again without needing to be resubmitted. This page also has a manifest, so it can be installed on a user\'s device.'
        ],
        gallery: ['Web/BudgetTracker/installed-site.png', 'Web/BudgetTracker/install-option.png'],
        slug: 'budget-tracker'
    },
    {
        title: 'Team Profile Generator',
        technologies: ['Node.js', 'JavaScript', 'Jest', 'NPM', 'Inquirer', 'CSS', 'HTML'],
        thumbnail: 'Web/TeamProfileGenerator/main.jpg',
        repo: 'https://github.com/JColeCodes/squad-dynamo',
        url: 'https://jcolecodes.github.io/squad-dynamo/dist/',
        client: null,
        description: [
            'This team profile generator works through the command line by asking a series of questions. The information submitted is saved as classes for different roles, which are a subclass of Employee. After the questions are answered, the generator takes the answer classes and applies the information to an HTML file and, along with copying over an accompanying CSS file, outputs it into the /dist folder.'
        ],
        gallery: ['Web/TeamProfileGenerator/main.jpg', 'Web/TeamProfileGenerator/distribution.png'],
        video: 'Web/TeamProfileGenerator/example.mp4',
        slug: 'team-profile-generator'
    }
];

export const gameProjectInfo = [
    {
        title: 'Happy Harvesters',
        technologies: ['CSS', 'Node.js', 'React', 'Express', 'GraphQL', 'MongoDB', 'Apollo', 'JavaScript', 'Adobe Illustrator'],
        thumbnail: 'Games/HappyHarvesters/main.jpg',
        repo: 'https://github.com/JColeCodes/happy-harvesters',
        url: 'https://happyharvesters.up.railway.app/',
        description: [
            'Happy Harvesters is a game where a user is able to log in, then harvest apples to make money and purchase upgrades. This browser-based game was created with the MERN stack as a single page application and saves user data to a Mongo database using GraphQL API. The project was the work of a group created in just 2 weeks time.',
            'As a coder: My main focus was creating the CSS and styling a mobile-responsive game with bold colors. I used advanced CSS that included animations, such as the apple falling off the tree, and color/skew effects to create shadows, such as on the splash page with the tree and barn. I coded how the modals look and function, and I also managed some bug fixes for the backend.',
            'As an illustrator: I used Adobe Illustrator to create vector art. I used a pen stylus to draw the items and used gradients to provide depth. The images I did include the tree, apple, apple basket, and gem.',
            'I hope to resume working on this game to make it better. There are known bugs that still remain to be resolved.'
        ],
        gallery: ['Games/HappyHarvesters/desktop-splash.gif', 'Games/HappyHarvesters/desktop-dashboard.png', 'Games/HappyHarvesters/desktop-orchard.png', 'Games/HappyHarvesters/desktop-shop.png', 'Games/HappyHarvesters/tablet-login.png', 'Games/HappyHarvesters/mobile-splash.png', 'Games/HappyHarvesters/mobile-dashboard.png', 'Games/HappyHarvesters/mobile-orchard.png'],
        embed: 'happyharvesters',
        slug: 'happy-harvesters'
    },
    {
        title: 'Sunny Puzzle Game',
        technologies: ['Adobe Animate', 'JavaScript'],
        thumbnail: 'Games/Thumbnails/SunnyGame.png',
        repo: null,
        url: 'https://colecodes.com/apps/sunny/Project9_Game_Cole.html',
        description: [
            'This puzzle game was created using Adobe Animate with JavaScript for functionality. Each puzzle game is a separate keyframe (there are 5 keyframes total). There is different code for each type of puzzle and each puzzle piece has its own specific code.',
            'All images used are copyrighted to FXX / FX / Fox / Disney. I do not own any of these images. They were used for the intent of learning.'
        ],
        gallery: null,
        embed: 'sunnygame',
        slug: 'sunny-puzzle-game'
    },
    {
        title: 'Liar\'s Dice',
        technologies: ['Java'],
        thumbnail: 'Games/Thumbnails/liarsdice_th.png',
        repo: null,
        url: 'https://repl.it/@Capauldi/LiarsDice?lite=true&outputonly=true',
        description: [
            'This text-based game was coded in Java. It is simple and allows a player to play against 3 computer players with random pirate names. Each user gets 5 dice and then, in turns, take bids on how many dice there are in total, or to call the previous player a "liar," which would end the turn. Points go to the winner of the round, and points are subtracted from the loser. The game goes until someone hits 0.',
            'This game uses SecureRandom to create random numbers, which are used for the dice, and for the pirate names. The game rounds functionality was created with while loops, if/else statements, and switch cases.'
        ],
        gallery: null,
        embed: 'liarsdice',
        slug: 'liars-dice'
    }
];

export const graphicProjectInfo = [
    {
        title: 'Digital Media Flyers',
        subtitle: 'West Valley College Digital Media',
        programs: ['Adobe Photoshop'],
        thumbnail: 'Graphic/WVDMFlyers/DigitalMedia.jpg',
        client: 'Digital Media Department at West Valley Collage',
        medium: 'Print, double-sided',
        description: [
            'I was tasked with creating new flyers for classes in the West Valley College Digital Media because there were new classes and information. I used the colors of the school and existing logo of the program to create the new flyers. The app design flyer features an iPhone vector I created. The images below show the front and back side of the flyers, side-by-side.'
        ],
        gallery: ['Graphic/WVDMFlyers/DigitalMedia.jpg', 'Graphic/WVDMFlyers/AppDesign.jpg', 'Graphic/WVDMFlyers/GraphicDesign.jpg', 'Graphic/WVDMFlyers/Flyers-Front.jpg', 'Graphic/WVDMFlyers/Flyers-Back.jpg'],
        slug: 'wvdm-flyers'
    },
    {
        title: 'Social Media Graphics',
        subtitle: 'West Valley College Fashion',
        programs: ['Adobe Photoshop'],
        thumbnail: 'Graphic/FashionShow/Launch.jpg',
        client: 'Fashion Department at West Valley College',
        medium: 'Web, Social Media',
        description: [
            'West Valley College had a fashion show, for which I made a few social media graphics. The first two were more fun, general graphics for Instagram, while the third is for information and the series of events that occurred, and for the latter, I was to use the school orange, the logo provided, and that specific font.'
        ],
        gallery: ['Graphic/FashionShow/FashionForward.jpg', 'Graphic/FashionShow/FashionPassion.jpg', 'Graphic/FashionShow/Launch.jpg'],
        slug: 'fashion-show-socials'
    },
    {
        title: '50th Anniversary Logos',
        subtitle: 'West Valley College Park Management',
        programs: ['Adobe Illustrator'],
        thumbnail: 'Graphic/Logos/ParkManagement/FinalPrototype_Bear.png',
        client: 'Park Management Department at West Valley College',
        medium: 'Logo for any medium - Print & Digital',
        description: [
            'The WPA (Works Progress Administration) has a history with National Parks. During the WPA movement, a number of Park Management posters were created to influence people to visit and view these natural sites in the USA. The head of the West Valley College Park Management department presented the illustrative WPA style as a starting point for inspiration for the 50th anniversary logo.',
            'As the school is located in California, the client requested the following to be featured on the logo: the mountains, the desert, and the redwoods. Also requested to be on the logo were the words: "West Valley College Park Management" and "50th Anniversary." He wanted the design to be in earth tones, such as browns, greens, and blues.',
            'After my initial sketches, I brought four designs to life in Illustrator, keeping in mind the illustrative style of the WPA. After meeting up again with the client, two of my designs were chosen for refinement. He wanted the years (1970-2020) to be on the logo, as well as the West Valley College logo. He also liked the idea of a badge-shaped design, which I implemented with my bear logo.'
        ],
        gallery: ['Graphic/Logos/ParkManagement/Sketches.jpg', 'Graphic/Logos/ParkManagement/InitialMocks.jpg', 'Graphic/Logos/ParkManagement/FinalPrototype_Bear.png', 'Graphic/Logos/ParkManagement/FinalPrototype_Tree.png', 'Graphic/Logos/ParkManagement/EnvironmentShirt_Bear.jpg', 'Graphic/Logos/ParkManagement/EnvironmentShirt_Tree.jpg', 'Graphic/Logos/ParkManagement/EnvironmentMug.jpg'],
        slug: 'park-management-logos'
    },
    {
        title: 'Ginny Bee Logo',
        subtitle: 'Ginny Bee',
        programs: ['Adobe Illustrator'],
        thumbnail: 'Graphic/Logos/GinnyBee/FinalLogo.png',
        client: 'Ginny Bee',
        client_url: 'https://ginnybee.com/',
        medium: 'Print',
        description: [
            'The Ginny Bee logo was designed for an independent besswax candle maker\'s business. The client had a vision of having a bee illustration that resembled a realistic bee, rather than a cartoon or something more abstract. The client provided fonts she wanted used and some sketches of what she had in mind. I then took what she provided and brought it to life in a digital vector format, done in Adobe Illustrator. After some changes, the final logo was created for a satisfied client.'
        ],
        gallery: ['Graphic/Logos/GinnyBee/InitialMocks.png', 'Graphic/Logos/GinnyBee/ClientsVision.png', 'Graphic/Logos/GinnyBee/InitialLogo.png', 'Graphic/Logos/GinnyBee/FinalLogo.png', 'Graphic/Logos/GinnyBee/LogoText.png', 'Graphic/Logos/GinnyBee/Physical-Logo.jpg'],
        slug: 'ginny-bee-logo'
    },
    {
        title: 'Artist Book',
        subtitle: 'My Japan',
        programs: ['Adobe Photoshop', 'Adobe Illustrator'],
        thumbnail: 'Graphic/ArtistBook/Digital-Pages56.jpg',
        client: null,
        medium: 'Print, book',
        description: [
            'For a class I was in, I was tasked to create an artist\'s book, which could be about anything I wanted it to be. As I am half-Japanese and my mother was in Japan at the time visiting her mother, I decided to make my book about my view of Japan, as my experience with Japan is very different than any others. The book reads right-to-left, as that is how books are read in Japan. Photographs of the final printed work will be added soon.'
        ],
        gallery: ['Graphic/ArtistBook/Digital-Pages56.jpg', 'Graphic/ArtistBook/Digital-Pages34.jpg', 'Graphic/ArtistBook/Digital-Pages12.jpg', 'Graphic/ArtistBook/Physical-Cover.jpg', 'Graphic/ArtistBook/Physical-Pages56.jpg', 'Graphic/ArtistBook/Physical-Pages34.jpg', 'Graphic/ArtistBook/Physical-Pages12.jpg'],
        slug: 'my-japan'
    },
    {
        title: 'Image Editing',
        subtitle: 'Microchip',
        programs: ['Adobe Photoshop'],
        thumbnail: 'Graphic/Microchip/MicrochipBoard02.jpg',
        client: 'Microchip',
        client_url: 'https://www.microchip.com/',
        medium: 'Digital',
        description: [
            'Microchip needed images of some of their upcoming boards, however the boards had not yet been produced, so I was tasked with artificially creating those board changes in Photoshop, including removing testing wires, making sure the company name was correct, removing some of the pieces that would not be on the new boards, and placing the image on a blank, white background.'
        ],
        gallery: ['Graphic/Microchip/MicrochipBoard01.jpg', 'Graphic/Microchip/MicrochipBoard02.jpg', 'Graphic/Microchip/MicrochipBoard03.jpg', 'Graphic/Microchip/MicrochipBoard04.jpg'],
        slug: 'microchip-images'
    },
    {
        title: 'Logo / Overlay Graphics',
        subtitle: 'Twitch.tv/DrGluon',
        programs: ['Adobe Illustrator'],
        thumbnail: 'Graphic/DrGluon/100babiesoverlay-1990s.png',
        client: 'DrGluon',
        client_url: 'https://www.twitch.tv/drgluon',
        medium: 'Digital',
        description: [
            'GluCon 2021: The GluCon 2021 was a design meant for print on white t-shirts (as part of a meme where DrGluon and chat joked about the GluCon merchandise being plain white Hanes T-shirts with sharpie writing). GluCon started as a fictional convention, but merchandize was made for the joke. I designed the 2021 design using Illustrator to give the text a fun and bouncy look. DrGluon designed the fez and face illustration and I included them as part of his branding. The Des Moines skyline is in the background, per DrGluon\'s request. (This design was made for print.)',
            'Hotel Challenge: For a Sims 4 challenge based on the BBC comedy Fawlty Towers, I created a Fawlty-style logo for DrGluon\'s challenge. The letters are titled and falling to suggest chaos and disorder.',
            'Babies Overlay Graphics: For a decades-themed Sims 4 baby challenge (100 Sims babies), DrGluon wanted an overlay piece to display the number of babies during his livestream. The 1960s one is in a cartoon style with a theme of space because of the 1960s Space Race. The 1990s one is inspired by the bold and colorful graphic style seen in 1990s media. Artwork, font, and color inspirations were pulled from various advertisements from the respective decades.'
        ],
        gallery: ['Graphic/DrGluon/glucon2021.png', 'Graphic/DrGluon/hotels3.png', 'Graphic/DrGluon/100babiesoverlay-1960s.png', 'Graphic/DrGluon/100babiesoverlay-1990s.png'],
        slug: 'drgluon-graphics'
    }
];

export const uiuxProjectInfo = [
    {
        title: 'Lizard Friends',
        thumbnail: 'UIUX/LizardFriends/MockupDesktop_LizardList.png',
        description: [
            'Lizard Friends was a design proposal project for a hypothetical site for reptile enthusiasts to learn more about different species of lizards. I created a site map of how the site would be laid out, sketched out potential designs of how the site would look across devices, picked out colors/fonts that could be used, and wrote out user profiles of potential site users. Then, I created digital mockups of what the site would look like if coded for desktop, tablet, and mobile sizes.'
        ],
        gallery: ['UIUX/LizardFriends/InterfacePlanning01.png', 'UIUX/LizardFriends/InterfacePlanning02.png', 'UIUX/LizardFriends/InterfacePlanning03.png', 'UIUX/LizardFriends/InterfacePlanning04.png', 'UIUX/LizardFriends/MockupDesktop_LizardList.png', 'UIUX/LizardFriends/MockupDesktop_Info.png', 'UIUX/LizardFriends/MockupDesktop_EditProfile.png', 'UIUX/LizardFriends/MockupTablet_LizardList.png', 'UIUX/LizardFriends/MockupPhone_LizardList.png', 'UIUX/LizardFriends/MockupPhone_Info.gif', 'UIUX/LizardFriends/MockupPhone_Login.gif', 'UIUX/LizardFriends/MockupPhone_EditProfile.gif'],
        slug: 'lizard-friends'
    },
    {
        title: 'Travel Site',
        thumbnail: 'UIUX/TravelSite/TravelMockup.png',
        description: [
            'This is a mockup of a travel site, which shows the full fonts, colors, sizing on a 1080p screen, and more of a potential location page.'
        ],
        gallery: ['UIUX/TravelSite/TravelMockup.png'],
        slug: 'travel-site'
    }
];

export const illustrationsInfo = [
    {
        image: 'Illustrations/AlwaysSunny-Charlie.jpg',
        title: 'Charlie Kelly from It\'s Always Sunny in Philadelphia',
        slug: 'iasip-charlie'
    },
    {
        image: 'Illustrations/AlwaysSunny-Dennis.jpg',
        title: 'Dennis Reynolds from It\'s Always Sunny in Philadelphia',
        slug: 'iasip-dennis'
    },
    {
        image: 'Illustrations/AlwaysSunny-Mac.jpg',
        title: 'Mac from It\'s Always Sunny in Philadelphia',
        slug: 'iasip-mac'
    },
    {
        image: 'Illustrations/AlwaysSunny-Frank.jpg',
        title: 'Frank Reynolds from It\'s Always Sunny in Philadelphia',
        slug: 'iasip-frank'
    },
    {
        image: 'Illustrations/AlwaysSunny-Dee.jpg',
        title: 'Dee Reynolds from It\'s Always Sunny in Philadelphia',
        slug: 'iasip-dee'
    },
    {
        image: 'Illustrations/ArjunGupta.png',
        title: 'Actor Arjun Gupta',
        slug: 'portrait-arjun'
    },
    {
        image: 'Illustrations/HaleAppleman.png',
        title: 'Actor Hale Appleman',
        slug: 'portrait-hale'
    },
    {
        image: 'Illustrations/MagiciansEliotDrink.png',
        title: 'Eliot Waugh from The Magicians',
        slug: 'magicians-eliot'
    },
    {
        image: 'Illustrations/MagiciansWillYouPlay.png',
        title: 'Alice Quinn, Quentin Coldwater, and Eliot Waugh from The Magicians',
        slug: 'magicians-play'
    },
    {
        image: 'Illustrations/PaulRuddWHAS.png',
        title: 'Actor Paul Rudd',
        slug: 'portrait-rudd'
    },
    {
        image: 'Illustrations/DannyRand_Unfin.jpg',
        title: 'Danny Rand from Marvel\'s Iron Fist',
        slug: 'marvel-rand'
    },
    {
        image: 'Illustrations/IASIP-Hogwarts.png',
        title: 'It\'s Always Sunny in Philadelphia characters in the Harry Potter world',
        slug: 'iasip-hogwarts'
    },
    {
        image: 'Illustrations/BuckyNat.png',
        title: 'Bucky Barnes and Black Widow from Marvel',
        slug: 'marvel-cartoon'
    },
    {
        image: 'Illustrations/MagiciansHighKing.png',
        title: 'The four High Kings from The Magicians',
        slug: 'magicians-king'
    }
];

export const vectorsInfo = [
    {
        image: 'Vectors/Penny.png',
        title: 'Penny Adiyodi in blocked colors',
        slug: 'magicians-penny'
    },
    {
        image: 'Vectors/Alice.png',
        title: 'Alice Quinn with use of text',
        slug: 'magicians-alice'
    },
    {
        image: 'Vectors/DarkKing.png',
        title: 'The Dark King, Sebastian Chatwin, in a stained glass style',
        slug: 'magicians-sebastian'
    },
    {
        image: 'Vectors/Eliot_Penny_Swap.png',
        title: 'Penny and Eliot swap with use of patterns',
        slug: 'magicians-swap'
    },
    {
        image: 'Vectors/Eliot_Monster.png',
        title: 'Eliot vs Monster reflection piece',
        slug: 'magicians-monster'
    },
    {
        image: 'Vectors/MARGO_King.png',
        title: 'Margo Hanson, part 1',
        slug: 'magicians-margo-1'
    },
    {
        image: 'Vectors/MARGO_Banished.png',
        title: 'Margo Hanson, part 2',
        slug: 'magicians-margo-2'
    },
    {
        image: 'Vectors/Whitespire_Landscape.png',
        title: 'Castle Whitespire fantasy castle landscape',
        slug: 'magicians-whitespire'
    }
];

export const animationsInfo = [
    {
        title: 'Autodesk Maya (3D Animation)',
        type: 'maya',
        content: {
            images: [
                {
                    image: 'Animations/Maya/Cole_Jennifer_Tree_Texture_Dynamics.gif',
                    title: 'Animation of leaves falling off tree',
                    slug: 'maya-tree'
                },
                {
                    image: 'Animations/Maya/Cole_Jennifer_Butterfly.gif',
                    title: 'Animation of butterfly flying in circle',
                    slug: 'maya-butterfly'
                },
            ],
            videos: [
                {
                    title: 'Lego Helicopter Animation',
                    video: 'Animations/Maya/Cole_Jennifer_Helicopter.mp4',
                    thumbnail: 'Animations/Maya/HelicopterFlight_th.jpg',
                    description: ['I modeled each and every single Lego piece seen in this animation.']
                },
            ],
        },
        description: 'Animations and 3D modeling I made in Autodesk Maya.'
    },
    {
        title: 'Adobe Animate (2D Animation)',
        type: 'adobe-animate',
        content: {
            videos: [
                {
                    title: 'Dayman Animation',
                    video: 'Animations/Dayman/DAYMAN_ColeCodes.mp4',
                    thumbnail: 'Animations/Dayman/Dayman_th.jpg',
                    description: ['Audio is copyrighted to FXX / FX / Fox / Disney. It was used for the intent of learning.']
                }
            ]
        },
        description: 'Animations I made in Adobe Animate. All graphics and illustrations by me.'
    },
];