import { Link } from "react-router-dom";
import { mobileClose } from '../utils/helpers';

function ProjectThumb({ project }) {
    return(
        <Link to={`${window.location.pathname}/${project.slug}`} onClick={mobileClose} className='project-link'>
            <div className="project-thumb">
                <img src={require(`../assets/images/${project.thumbnail}`)} alt={`${project.title}`} />
                <div className="project-thumb-overlay"></div>
                <div className="project-thumb-text">
                    <h4>{project.title}</h4>
                    <h5>
                        {project.subtitle && <span>{project.subtitle}</span>}
                        {project.technologies && project.technologies.map(tech => (
                            <span key={tech}>{tech}</span>
                        ))}
                    </h5>
                </div>
            </div>
        </Link>
    );
}

export default ProjectThumb;