import ProjectThumb from './ProjectThumb';
// import Section from './Section';

function ProjectList({ title, projectList, description, sections }) {
    return(
        <section>
            <h3>{title}</h3>
            {description && description.map(desc => (
                <p key={desc}>
                    {desc}
                </p>
            ))}
            <p><i>Click thumbnails to see more information about the projects.</i></p>
            <div className='project-list'>
                {projectList.map(project => (
                    <ProjectThumb project={project} key={project.slug} />
                ))}
            </div>
            {/* {sections && <>
                {sections.map(sect => (
                    <Section sect={sect} key={sect.title} />
                ))}
            </>} */}
        </section>
    );
}

export default ProjectList;