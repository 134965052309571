import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import jcface from '../../assets/images/me2022.jpg';

function About() {
    const quickInfo = [
        { title: "U.C. Berkeley Extension", subtitle: "Coding Certificate" },
        { title: "Silicon Valley", subtitle: "Based in California; but can relocate" },
        { title: "Experience", subtitle: "Over a decade with code and design!" },
    ];
    const quickSkills = ["Git", "HTML + CSS", "JavaScript + jQuery", "Node.js + React", "Photoshop + Illustrator", "...And More!"];
    return(
        <section id="about" className="about-me">
            <div className="quick-info">
                {quickInfo.map((info, i) => (
                    <div className="info-box" id={`info-${i + 1}`} key={`info-${i + 1}`}>
                        <div className='info-inner'>
                            <div className='info-title'>{info.title}</div>
                            <p>{info.subtitle}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="main-content">
                <h3>My name is Jennifer Cole and...</h3>
                <div className="self-image">
                    <img src={jcface} loading="lazy" alt="Jennifer Cole" />
                </div>
                <p>
                    I am a Silicon Valley-based independent web and graphic designer, and casual illustrator. I have experience with designing for both WordPress themes, as well as non-WordPress sites. All of my final coding is mobile responsive. I also have over a decade experience working with Adobe Photoshop. I really enjoy combining the technical aspects of coding with the artistic aspects of graphic design. I'm highly adaptable to what needs to be done and can quickly learn anything that needs to be learned for a job.
                </p>
                <p>
                    Prior to the COVID-19 pandemic, I worked with the West Valley College digital media department as the student assistant. I have fully coded, from scratch, the current West Valley Digital Media site, and have created flyers for the department. I then went on to do U.C. Berkeley Extension's Coding Bootcamp and was able to learn and expand my knowledge to encapulate the MERN stack and do a lot more JavaScript-based coding.
                </p>
                <p className='brand'>
                    Full-stack web developer with a specialty in front-end development with a Certificate in Full-Stack Development from UC Berkeley Extension. Passionate about learning new skills as technology and code evolves, and using these skills to design and develop exciting, new web applications. A very versatile designer with a mind for logic and an eye for aesthetics who has an intimate understanding of both the code and graphic aspects of a design.
                </p>

                <h4 className='center'>My Skills</h4>
                <ul className="quick-skills">
                    {quickSkills.map(skill => (
                        <li key={skill}>{skill}</li>
                    ))}
                </ul>
                <div className="code-logos">
                    <i className="fa-brands fa-git" data-tip="Git"></i>
                    <i className="fa-brands fa-html5" data-tip="HTML 5"></i>
                    <i className="fa-brands fa-css3-alt" data-tip="CSS 3"></i>
                    <i className="fa-brands fa-js" data-tip="JavaScript"></i>
                    <i className="fa-brands fa-node" data-tip="Node.js"></i>
                    <i className="fa-brands fa-react" data-tip="React.js"></i>
                    <i className="fa-brands fa-wordpress" data-tip="WordPress"></i>
                    <i className="fa-brands fa-cpanel" data-tip="cPanel"></i>
                    <i className="fa-brands fa-php" data-tip="PHP"></i>
                    <i className="fa-brands fa-bootstrap" data-tip="Bootstrap"></i>
                    <i className="fa-brands fa-linux" data-tip="Linux"></i>
                    <i className="fa-brands fa-python" data-tip="Python"></i>
                    <i className="fa-brands fa-java" data-tip="Java"></i>
                    
                    <ReactTooltip place="bottom" effect="float" className="tooltip" textColor="#f2f1eb" backgroundColor="#2a2929" />
                </div>
                <p className='center'>
                    <Link to='./resume' className='button button-resume'>View My Resume <i className="fa-solid fa-arrow-right-long"></i></Link>
                </p>
            </div>
        </section>
    );
}

export default About;