import { NavLink } from 'react-router-dom';
import Navigation from './Navigation';

function Header({ pages, subPages }) {
    return(
        <header className={window.location.pathname === '/' ? 'index-header' : '' }>
            {/* Site Title */}
            <div className='title'>
                <h1>{window.location.pathname === '/' ?
                    'Jennifer Cole' :
                    <NavLink to='/'>Jennifer Cole</NavLink>}
                </h1>
                <h2>
                    Web. Graphic. Design.
                </h2>
            </div>
            {/* Site Navigation */}
            <Navigation pages={pages} subPages={subPages} />
        </header>
    );
}

export default Header;