export const codingPages = [
    {
        title: 'Web Development',
        subtitle: 'Websites and Applications',
        thumbnail: 'Web/WeatherDash/screenshot.jpg',
        slug: 'web'
    },
    {
        title: 'Games',
        subtitle: 'Games Coded by Me',
        thumbnail: 'Games/Thumbnails/SunnyGame.png',
        slug: 'games'
    }
];

export const designPages = [
    {
        title: 'Graphic Design',
        subtitle: 'Adobe Photoshop & Illustrator',
        thumbnail: 'Graphic/ArtistBook/Digital-Pages56.jpg',
        slug: 'graphics'
    },
    {
        title: 'UI / UX',
        subtitle: 'Mockups of Sites / Apps',
        thumbnail: 'UIUX/LizardFriends/MockupDesktop_Info.png',
        slug: 'ui-ux'
    }
];

export const galleryPages = [
    {
        title: 'Digital Illustrations',
        subtitle: 'Adobe Photoshop',
        thumbnail: 'Illustrations/ArjunGupta.png',
        slug: 'digital-illustrations'
    },
    {
        title: 'Vector Art',
        subtitle: 'Adobe Illustrator',
        thumbnail: 'Vectors/Whitespire_Landscape.png',
        slug: 'vector-art'
    },
    {
        title: 'Animations',
        subtitle: '2D: Adobe Animate / 3D: Maya',
        thumbnail: 'Animations/Dayman/Dayman_th.jpg',
        slug: 'animations'
    }
];