import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { webProjectInfo, gameProjectInfo, graphicProjectInfo, uiuxProjectInfo } from '../assets/information/projects';
import ModalContent from '../components/ModalContent';

function Modal({setIsModal}) {
    const navigate = useNavigate();
    let params = useParams();
    
    const backLink = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'));
    const closeModal = () => {
        setIsModal(false);
        if (backLink !== '/coding' && backLink !== '/design') {
            navigate(backLink, {replace: true}, [navigate]);
        }
    };

    useEffect(() => {
        setIsModal(true);
    }, [setIsModal]);

    let projects = [].concat(webProjectInfo, gameProjectInfo, graphicProjectInfo, uiuxProjectInfo);
    return(
        <>
        <div className='modal-container'>
            <div className='modal-background' onClick={closeModal}></div>
            {projects.map((project) => (project.slug === params.slug &&
                <div key={project.title}>
                    <ModalContent project={project} />
                </div>
            ))}
            <div className='modal-close-btn' onClick={closeModal}>
                <i className='fa-solid fa-x'></i>
            </div>
        </div>
        </>
    );
}

export default Modal;