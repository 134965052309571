import Gallery from './Gallery';

function Section({title, projectList, description}) {
    return(
        <>
            <section className="subsection-title" >
                <h3>{title}</h3>
                    {description && description.map(desc => (
                        <p key={desc}>
                            {desc}
                        </p>
                    ))}
            </section>
            {projectList.map(sect => (
                    <div className="subsection" key={sect.type}>
                        <h4>{sect.title}</h4>
                        <p>{sect.description}</p>
                        {sect.content.images && <Gallery projectList={sect.content.images} />}
                        {sect.content.videos && sect.content.videos.map(proj => (
                            <div key={proj.title}>
                                <><video src={require(`../assets/images/${proj.video}`)}
                                poster={require(`../assets/images/${proj.thumbnail}`)} controls preload="auto"></video>
                                <p><i>{proj.description}</i></p></>
                            </div>
                        ))}
                    </div>
                ))}
        </>
    );
}

export default Section;