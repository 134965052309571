export const sections = ["skills", "experience", "education"];
export const skills = [
    {
        title: "Languages",
        specifics: [ "HTML5", "CSS3", "JavaScript", "jQuery", "PHP", "JSON", "Regex", "Python", "Java", "C" ],
        slug: "languages"
    }, {
        title: "Full Stack Development",
        specifics: [ "MongoDB", "Express.js", "React.js", "Next.js", "Node.js", "MySQL", "Handlebars", "RESTful APIs", "GraphQL", "DOM", "AJAX", "Bootstrap", "PWA (Progressive Web Applications)" ],
        slug: "fullstack"
    }, {
        title: "Concepts",
        specifics: [ "OOP (Object-Oriented Programming)", "ORM (Object-Relational Mapping)", "MVC (Model-View-Controller)", "CRUD (Create, Read, Update, Delete)" ],
        slug: "concepts"
    }, {
        title: "Graphic Design",
        specifics: [ "Adobe Photoshop", "Adobe Illustrator", "Color theory", "Typography" ],
        slug: "graphic"
    }, {
        title: "Other",
        specifics: [ "Git", "Heroku", "WordPress", "cPanel", "FTP", "XCode", "Linux", "Adobe Animate", "Autodesk Maya" ],
        slug: "other"
    }
]
export const experience = [
    {
        years: "September 2023 - Present",
        role: "Technician I - Engineering (Tech Support)",
        comp: "Microchip Technology",
        location: "San Jose, CA (On-Site)",
        description: [
            "Installed, upgraded, and uninstalled TimePictra via remote Linux machines.",
            "Responded to customer tickets through Salesforce, providing license files.",
            "Provided feedback on changes made to the CSS and JavaScript on the next generation design for TimePictra (React)."
        ]
    },
    {
        years: "February 2023 - September 2023",
        role: "Web Graphics Design Intern",
        comp: "Microchip Technology",
        location: "San Jose, CA (On-Site)",
        description: [
            "Coded a working design demo with HTML, CSS, and JavaScript for a next generation design for a web GUI for TimePictra.",
            "Coded a prototype of the next generation design for TimePictra in React, using JSX.",
            "Created over 70 different custom .SVG (vector) icons, including more than 5 animated icons. Also created an icon font with those custom icons."
        ]
    },
    {
        years: "July 2017 - May 2020",
        role: "Digital Media TA",
        comp: "West Valley College",
        location: "Saratoga, CA (On-Site)",
        description: [
            "Designed 5 different double-sided flyers for available classes for the Digital Media department.",
            "Coded a website for the Digital Media department using HTML, CSS, PHP, and Javascript."
        ]
    }
];
export const education = [
    {
        years: "April 2022",
        school: "UC Berkeley Extension",
        location: "Berkeley, CA",
        certification: "Certificate in Coding",
        classes: null
    }, {
        years: "May 2021",
        school: "West Valley College",
        location: "Saratoga, CA",
        certification: "A.A Degree in Web & App Development",
        classes: "Visual Design for New Media, Portfolio Planning, Internet Marketing, Mobile Marketing, Adobe Animate, Python Programming, and MORE!"
    }
];