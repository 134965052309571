import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Header from './components/Header';
import Footer from './components/Footer';

import { pages } from './assets/information/pages';

let mainPages = [];
let subPages = [];
let subPagesNoArr = [];
Object.keys(pages).forEach(sect => {
  let mainObj = (
    {
      path: pages[sect].path,
      title: pages[sect].title,
      Element: pages[sect].Element,
      projectList: pages[sect].projectList
    }
  );
  if (pages[sect].subpages) {
    let tempSubArr = [];
    pages[sect].subpages.forEach(page => {
      let pageObj = {
        path: page.path,
        title: page.title,
        Element: page.Element,
        Parent: page.Parent,
        projectList: page.projectList,
        description: page.description
      };
      tempSubArr.push(pageObj);
      subPagesNoArr.push(pageObj);
    });
    subPages.push(tempSubArr);
  };
  if (pages[sect].sections) {
    let tempSectArr = [];
    pages[sect].sections.forEach(section => {
      let sectObj = ({
        title: section.title,
        Element: section.Element,
        projectList: section.projectList,
        description: section.description
      });
      tempSectArr.push(sectObj);
    });
    mainObj.sections = tempSectArr;
  }
  mainPages.push(mainObj);
});
const allPages = mainPages.concat(subPagesNoArr);

function Template() {
  const location = useLocation();

  const [isModal, setIsModal] = useState(false);
  
  return(
    <div className={!isModal ? 'container' : 'container content-modal'}>
      {/* Header */}
      <Header pages={mainPages} subPages={subPages} />

      {/* Content */}
      {window.location.pathname !== '/' &&
        <div className="content">
            <TransitionGroup component={null}>
              <CSSTransition
                key={location.key}
                classNames={'fade'}
                timeout={300}
              >
                <Routes location={location}>
                  {allPages.map(({path, title, Element, Parent, projectList, description, sections}) => (
                    <Route
                      key={title}
                      path={path}
                      element={
                        <div>
                          { !Parent ?
                          <>
                            <Element title={title} projectList={projectList} description={description} sections={sections} />
                          </>
                          : 
                          <>
                            <Element setIsModal={setIsModal} />
                            <Parent title={title} projectList={projectList} description={description} />
                          </>
                          }
                        </div>
                      }
                    />
                ))}
                </Routes>
              </CSSTransition>
            </TransitionGroup>
        </div>
      }

      {/* Footer */}
      {window.location.pathname !== '/' &&
        <Footer />
      }

    </div>
  );
}

function delayTransition() {
  const classes = document.body.classList;
  let timer = 0;
  function delay() {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    else
        classes.add('stop-transitions');

    timer = setTimeout(() => {
        classes.remove('stop-transitions');
        timer = null;
    }, 1);
  }
  window.addEventListener('resize', delay);
  window.onload = delay();
}

function App() {
  return (
    <Router>
      <Template />
      {delayTransition()}
    </Router>
  );
}

export default App;
