import { useState } from 'react';
import FsLightbox from 'fslightbox-react';

import { lightboxButtons } from '../utils/helpers';

function ModalContent({ project }) {
    const [lightboxToggle, setLightboxToggle] = useState({ toggler: false, sourceIndex: 0 });
    function openLightBoxSource(sourceIndex) {
        setLightboxToggle({ toggler: !lightboxToggle.toggler, sourceIndex: sourceIndex });
        setTimeout(lightboxButtons, 1);
    }

    let images = [];
    if (project.gallery) {
        project.gallery.forEach(img => { images.push(require(`../assets/images/${img}`)) });
    }

    const [iframeState, setIframeState] = useState({ index: 0 });
    const reloadIframe = () => {
        setIframeState({ index: iframeState.index + 1 });
    }

    return(
        <>
        <div className='modal'>
            <h3>{project.title}</h3>
            <ul>
                {project.client && <li><b>Client:</b> {project.client_url ? <a href={project.client_url} target="_blank" rel="noreferrer">{project.client}</a> : <>{project.client}</>}</li>}
                {project.technologies && <li><b>Technologies:</b> {project.technologies.map((tech, i) => (
                    <span key={tech}>{tech}{i !== project.technologies.length - 1 && ', '}</span>
                ))}</li>}
                {project.programs && <li><b>Programs:</b> {project.programs.map((prog, i) => (
                    <span key={prog}>{prog}{i !== project.programs.length - 1 && ', '}</span>
                ))}</li>}
                {project.medium && <li><b>Medium:</b> {project.medium}</li>}
                <li className='modal-buttons'>
                    {project.repo && <a href={project.repo} target='_blank' rel='noreferrer' className='button'><i className="fa-brands fa-github"></i> GitHub Link</a>}
                    {project.url && <a href={project.url} target='_blank' rel='noreferrer' className='button'><i className="fa-solid fa-link"></i> Deployed Site Link</a>}
                </li>
            </ul>
            {project.description.map(desc => (
                <p key={desc}>
                    {desc}
                </p>
            ))}
            {project.gallery && <>
                <h4>Screen Captures</h4>
                <p><i>Click thumbnails to see larger image.</i></p>
                <div className='gallery'>
                    {project.gallery.map((img, i) => (
                        <img src={require(`../assets/images/${img}`)} alt={`Sample for ${project.title}`} key={img} onClick={() => openLightBoxSource(i)} />
                    ))}
                </div>
            </>}
            {project.video && <div className='modal-video'>
                <h4>Example Video</h4>
                <video src={require(`../assets/images/${project.video}`)} poster={require(`../assets/images/${project.thumbnail}`)} controls preload="auto"></video>
            </div>}
            {project.embed && <>
                <h4>Play the Game Below</h4>
                <div className="insertgame">
                    <iframe className="gameframe" src={project.url} id={project.embed} title={project.title} key={iframeState.index} allowtransparency="true" allowFullScreen={true} sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-modals"></iframe>
                </div>
                <span className='iframe-refresh' onClick={reloadIframe}>Refresh the game</span>
            </>}
        </div>
        <FsLightbox
            toggler={lightboxToggle.toggler}
            sourceIndex={lightboxToggle.sourceIndex}
            sources={images}
        />
        </>
    );
}

export default ModalContent;