export function validateEmail(email) {
    var emailAddress = String(email);
    var regex = /^[^.\s]([\w.!#$%&'*+/=?^`{|}_~-]+)[^.\s]@[a-z0-9]+\.([a-z]+)(\.[a-z]{2,13})*$/i;
    return (regex.test(emailAddress) && !emailAddress.includes('..'));
}

export function lightboxButtons() {
    const closeBtn = document.querySelector('[title="Close"]');
    const prevBtn = document.querySelector('[title="Previous slide"] div');
    const nextBtn = document.querySelector('[title="Next slide"] div');
    closeBtn.innerHTML = `<i class="fa-solid fa-x"></i>`;
    if (prevBtn) prevBtn.innerHTML = `<i class="fa-solid fa-angle-left"></i>`;
    if (nextBtn) nextBtn.innerHTML = `<i class="fa-solid fa-angle-right"></i>`;
}

export function mobileClose() {
    const nav = document.querySelector('nav');
    const navClose = document.querySelector('#mobile-close');
    navClose.classList.add('fa-bars');
    navClose.classList.remove('fa-x');
    nav.classList.remove('mobile-toggle');
}