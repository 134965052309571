import { NavLink } from 'react-router-dom';
import { mobileClose } from '../utils/helpers';

function Navigation({ pages, subPages }) {
    const navPages = pages.filter((page) => page.title !== 'ColeCodes.com' && page.title !== 'Not Found');

    const mobileToggle = () => {
        const nav = document.querySelector('nav');
        const navClose = document.querySelector('#mobile-close');
        navClose.classList.toggle('fa-bars');
        navClose.classList.toggle('fa-x');
        nav.classList.toggle('mobile-toggle');
    }

    return(
        <nav>
            <div className='nav-title'>Navigation</div>
            <ul>
                {navPages.map(({title, path}, i) => (
                    <li key={title} className='main-nav'>
                        <NavLink to={path}
                        className={({ isActive }) => isActive ? 'active' : '' }
                        onClick={mobileClose} end>
                            {title}
                        </NavLink>
                        {subPages[i] && window.location.pathname !== '/' &&
                        <ul className='sub-menu'>
                            {subPages[i].map(page => {return !page.Parent &&
                                <li key={page.title}>
                                    <NavLink to={page.path}
                                    onClick={mobileClose}
                                    className={({ isActive }) => isActive ? 'active' : '' }>
                                        {page.title}
                                    </NavLink>    
                                </li>
                            })}
                        </ul>}
                    </li>
                ))}
            </ul>
            <div className='icon' onClick={mobileToggle}>
                <i className='fa-solid fa-bars' id='mobile-close'></i>
            </div>
        </nav>
    );
}

export default Navigation;