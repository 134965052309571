import Main from '../../pages/Main';
import NotFound from '../../pages/404';

import About from '../../pages/About';
import Resume from '../../pages/About/Resume';
import Contact from '../../pages/Contact';

import { webProjectInfo, gameProjectInfo, graphicProjectInfo, uiuxProjectInfo, illustrationsInfo, vectorsInfo, animationsInfo } from './projects';
import { codingPages, designPages, galleryPages } from './subpages';

import Modal from '../../components/Modal';
import ProjectList from '../../components/ProjectList';
import Gallery from '../../components/Gallery';
import Section from '../../components/Section';

export const pages = {
    main: {
        path: '/',
        title: 'ColeCodes.com',
        Element: Main,
    },
    about: {
        path: '/about',
        title: 'About',
        Element: About,
        subpages: [
            {
                path: '/about/resume',
                title: 'Resume',
                Element: Resume
            }
        ]
    },
    coding: {
        path: '/coding',
        title: 'Coding',
        Element: ProjectList,
        projectList: codingPages,
        subpages: [
            {
                path: '/coding/web',
                title: 'Web Development',
                Element: ProjectList,
                projectList: webProjectInfo
            },
            {
                path: '/coding/web/:slug',
                title: 'Web Development',
                Element: Modal,
                Parent: ProjectList,
                projectList: webProjectInfo
            },
            {
                path: '/coding/games',
                title: 'Games',
                Element: ProjectList,
                projectList: gameProjectInfo
            },
            {
                path: '/coding/games/:slug',
                title: 'Games',
                Element: Modal,
                Parent: ProjectList,
                projectList: gameProjectInfo
            }
        ]
    },
    design: {
        path: '/design',
        title: 'Design',
        Element: ProjectList,
        projectList: designPages,
        subpages: [
            {
                path: '/design/graphics',
                title: 'Graphic Design',
                Element: ProjectList,
                projectList: graphicProjectInfo
            },
            {
                path: '/design/graphics/:slug',
                title: 'Graphic Design',
                Element: Modal,
                Parent: ProjectList,
                projectList: graphicProjectInfo
            },
            {
                path: '/design/ui-ux',
                title: 'UI / UX',
                Element: ProjectList,
                projectList: uiuxProjectInfo
            },
            {
                path: '/design/ui-ux/:slug',
                title: 'UI / UX',
                Element: Modal,
                Parent: ProjectList,
                projectList: uiuxProjectInfo
            }
        ]
    },
    gallery: {
        path: '/gallery',
        title: 'Gallery',
        Element: ProjectList,
        projectList: galleryPages,
        subpages: [
            {
                path: '/gallery/digital-illustrations',
                title: 'Digital Illustrations',
                Element: Gallery,
                projectList: illustrationsInfo,
                description: ['The following pieces were made in Adobe Photoshop.']
            },
            {
                path: '/gallery/vector-art',
                title: 'Vector Art',
                Element: Gallery,
                projectList: vectorsInfo,
                description: ['The following pieces were made in Adobe Illustrator.']
            },
            {
                path: '/gallery/animations',
                title: 'Animations',
                Element: Section,
                projectList: animationsInfo,
                description: ['The following pieces were made in various programs for different types of animation.']
            }
        ],
        // sections: [
        //     {
        //         title: 'Animations',
        //         projectList: animationsInfo,
        //         description: ['Animations I made in Adobe Animate. All graphics and illustrations by me.']
        //     }
        // ]
    },
    contact: {
        path: '/contact',
        title: 'Contact',
        Element: Contact
    },
    notFound: {
        path: '/*',
        title: 'Not Found',
        Element: NotFound
    }
};